import { useLocation } from "react-router-dom";

import { useSetLocation } from "@services/reactRouterDom";

const useNavigateToUser = () => {
  const { hash } = useLocation();
  const setLocation = useSetLocation();

  return function navigateToUser(userId: string | null) {
    if (hash) setLocation({ hash: userId ? `#${userId}` : "" });
    else {
      setLocation(
        { pathname: `/admin/users/${userId ?? ""}` },
        { replace: true }
      );
    }
  };
};

export default useNavigateToUser;
